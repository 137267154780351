<template>
	<section class="latest container spaced">
		<!-- Post Listing -->

		<div class="grid">
			<div class="half">
				<h3 class="header3">Latest Insights</h3>
			</div>
			<div class="half aright">
				<ButtonsLink label="View All" action="/insights" />
			</div>

			<div class="third" v-for="(post, index) in posts?.results" :key="post.uid">
				<NuxtLink :to="`/insights/${post?.uid}`" class="post">
					<div class="img-wrapper">
						<div class="reading-time">
							<i class="fa fa-light clock"></i>{{ post?.data.reading_time }}m Read
						</div>
						<PrismicImage defer :field="index == 0 ? post?.data.featured_image : post?.data.featured_image.featured"
							class="img-fluid" />
					</div>

					<div class="description">
						<span class="tag" v-for="tag in post?.tags" :key="tag">
							{{ tag }}
						</span>
						<h2 class="header4">{{ post?.data.title }}</h2>
						<h3 class="header6">{{ post?.data.subtitle }}</h3>
						<PrismicRichText :field="post?.data.intro" class="truncate" />
					</div>
				</NuxtLink>
			</div>
		</div>

	</section>
</template>

<script setup>
const { client } = usePrismic();

const { data: posts } = useAsyncData("posts", () =>
	client.getByType("posts", {
		orderings: {
			field: 'document.first_publication_date',
			direction: 'desc',
		},
		pageSize: 3,
		page: 1
	})
);
</script>

<style lang="scss" scoped>
.latest {
	background: var(--color-white);
	color: var(--color-black);
}

.listing {
	margin-top: 30px;
}

.img-wrapper {
	overflow: hidden;
	margin-bottom: 30px;
	position: relative;

	.reading-time {
		position: absolute;
		bottom: 0px;
		right: 0px;
		text-align: right;
		padding: 10px 20px;
		background: var(--color-primary);
		color: var(--color-white);
		font-size: 14px;
		z-index: 1000;
	}

	img {
		transform: scale(1.05);
		transition: all .5s ease-out;
	}
}

a {
	color: var(--color-black);

	&:hover {
		img {
			transform: scale(1.2);
		}

		h2 {
			color: var(--color-primary);
		}
	}
}

.post {
	display: block;
	margin-bottom: 80px;
}

.truncate {
	display: -webkit-box;
	-webkit-line-clamp: var(--max-truncate-lines);
	-webkit-box-orient: vertical;
	overflow: hidden;
	hyphens: auto;
	margin-bottom: 20px;
}

h2 {
	margin-bottom: 0px;
}

h2+h3 {
	margin-top: 10px;
	font-family: var(--font-bold);
}

@media only screen and (max-width: 767px) {
	.aright {
		justify-self: center;
		margin-bottom: 60px;
	}
}
</style>